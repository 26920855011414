import { Link } from 'react-router-dom'
import '../styles/ErrorPage.scss'
import { Helmet } from 'react-helmet'
export const ErrorPage = () => {
    return (
        <div className="ErrorPage">
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <h1>
                Sorry, page not found.
            </h1>
            <Link to='/'>
                Return Home
            </Link>
        </div>
    )
}