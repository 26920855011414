import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { Helmet } from 'react-helmet';
import { Home } from './components/Home';
import { Resume } from './components/Resume'
import Loading from './components/Loading';
import { Close, List } from '@mui/icons-material';
import { useGSAP } from '@gsap/react';
import SplitType from 'split-type';
import { TextPlugin } from 'gsap/all';
import gsap from 'gsap';
import eIncLogoWhite from './images/EstebanInc-Logo-White.png'
import { BrowserRouter, Routes, Route, useNavigate, NavLink } from 'react-router-dom';
import { ErrorPage } from './components/ErrorPage';
import './styles/App.scss'
import { Fade } from '@mui/material';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(TextPlugin)


export default function App() {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => setLoading(false), 1500)
  }, [])
  if (loading) {
    return <Loading />
  }
  return (
    <BrowserRouter>
      {/* <Helmet>
        <title>Esteban Inc. | Multidisciplinary Designer, Developer & Artist | Creative Solutions Across All Media</title>

        <meta name="description" content="Esteban Inc. is a multidisciplinary, one-woman show offering a comprehensive range of creative services. From design and development to artistry, let’s bring your vision to life with innovative, high-quality solutions tailored to meet the unique needs of businesses and individuals alike. Create something extraordinary with Esteban Inc." />

        <meta name="keywords" content="multidisciplinary designer, developer, artist, creative services, one-woman show, Esteban Inc., innovative solutions, high-quality design, comprehensive creative services, business solutions, individual services, tailored design, creative development, artistic solutions, ui, ux, front end developer, denver, graphic designer, logo design, branding, front-end development, unique designs, handmade, handmade work, fine arts, denver artist, denver designer" />

        <meta property="og:title" content="Esteban Inc. | Multidisciplinary Designer, Developer & Artist | Creative Solutions Across All Media" />
        <meta property="og:description" content="Discover Esteban Inc., a multidisciplinary, one-woman show offering creative services tailored to your unique needs. Let's bring your vision to life with innovative solutions across all media." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://esteban-inc.com" />
        <meta property="og:image" content="/images/E-INC-LOGO.webp" />

        <meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="Multidisciplinary Designer, Developer & Artist | Steban Inc." />
<meta name="twitter:description" content="Steban Inc. offers comprehensive creative services to meet your unique needs. Let’s create something extraordinary together." />
<meta name="twitter:image" content="[Link to Your Featured Image]" />
      </Helmet> */}
      <div className="App wrapper">
        <FixedMenu />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/resume' element={<Resume />} />
          <Route path='*' element={<ErrorPage />} />
        </Routes>

        <footer>
          <Grid container className='contactFooter' id="contact">
            <Grid item xs={12} lg={12}>
              <div id="contactMe">Let's Collaborate.
                {/* <div className="fade-container">
                  <span>Extraordinary</span>
                  <span>Exceptional</span>
                  <span>Elegant</span>
                  <span>Eclectic</span>
                  <span>Eloquent</span>
                  <span>Engaging</span>
                  <span>Experienced</span>
                  <span>Energetic</span>
                </div> */}
              </div>

              <iframe
                className='jotFormFrame'
                loading='lazy'
                id="JotFormIFrame-241797387833069"
                title="Esteban Inc. Contact Form"
                allow="geolocation; microphone; camera; fullscreen"
                src="https://form.jotform.com/241797387833069"
                frameBorder={0}
                allowtransparency="true"
                scrolling='no'
                style={{
                  "minWidth": '100%',
                  'maxWidth': '100%',
                  'height': '700px',
                  'border': 'none',
                  'minHeight': '700px'
                }}
              >
              </iframe>

            </Grid>


            <Grid item xs={12} lg={12}>
              <ul>
                <li>  <a href="mailto:emylee@esteban-inc.com" target="_blank" title='Mail Link to Emylee@Esteban-inc.com'>Emylee@Esteban-Inc.com</a></li>
                <li><a href='https://estebaninc.etsy.com' title='Etsy' target='_blank'>Etsy</a></li>
                <li><a href='https://www.instagram.com/esteban.inc/' title='Instagram' target='_blank'>Instagram</a></li>
                <li><a href='https://www.tiktok.com/@esteban_inc' title='TikTok' target='_blank'>TikTok</a></li>
                <li><a href='https://www.linkedin.com/in/emyleeesteban/' title='LinkedIn' target='_blank'>LinkedIn</a></li>

                <li><NavLink to='/resume'>
                  Resume
                </NavLink></li>
                {/* <li>
                  <a aria-label='Enjoy Bacon?' title='Enjoy Bacon?' target="_blank" href="https://baconologist.com">Enjoy Bacon?</a>
                </li> */}
              </ul>
            </Grid>
          </Grid>
        </footer>

      </div>
    </BrowserRouter>


  )
}

const FixedMenu = () => {
  const [toggleMenu, setToggleMenu] = useState(false)
  const [bcMenu, setBCMenu] = useState(false)
  const container = useRef();

  const navigate = useNavigate()

  const onClickGood = () => {
    setToggleMenu(!toggleMenu)

    if (!toggleMenu) {
      let mySplitText = new SplitType("#menuText", { type: "words" })
      let tl = gsap.timeline()
      mySplitText.split({ type: "lines" })

      tl.from(mySplitText.lines, {
        duration: 0.5,
        delay: .5,
        opacity: 0,
        rotationX: -120, force3D: true,
        transformOrigin: "top center -150px", stagger: 0.1
      })

      const bodyStyle = document.body

      bodyStyle.style.overflow = 'hidden'

    } else {
      const bodyStyle = document.body

      bodyStyle.style.overflow = 'auto'
    }
  }


  return (

    <nav ref={container} className={!toggleMenu ? 'menuNav' : 'menuNav fullHeight'}>
      <Dialog
        className='bcModal'
        open={bcMenu}
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setBCMenu(false)
        }}>
        <DialogContent sx={{ textAlign: 'center', width: ' 95%', margin: 'auto', }}>

          <Typography sx={{ paddingBottom: '10px' }} className='dialog-title'>Hey There!</Typography>

          <Typography variant='body1' sx={{ paddingBottom: '10px' }}>
            Nice to meet you! If you found a business card that means I saw your logo and thought I might be able to help your company out, or perhaps you found it in a coffee shop or bulletin board around town.
          </Typography>


          <Typography variant='body1'>
            <b>I'd like to offer a discount on any service you may need.</b> <br />
            Please reach me at <b>emylee@esteban-inc.com</b> or fill out the form below.
          </Typography>



          <iframe
            loading='lazy'
            id="JotFormIFrame-242137211891149"
            title="Esteban Inc. Business Card Form"
            allowtransparency="true"
            allow="geolocation; microphone; camera; fullscreen"
            src="https://form.jotform.com/242137211891149"
            frameBorder="0"
            scrolling="no"
            style={{ "minWidth": "100%", "maxWidth": "100%", "height": "50px", "border": "none;" }}
          >
          </iframe>


        </DialogContent>
      </Dialog>

      <Grid container justifyContent={'space-between'}
        alignContent={'center'} alignItems={'center'}>
        <Grid item xs={12}>
          <Grid container justifyContent={'space-between'}
            alignContent={'center'} alignItems={'center'}>
            <Grid item xs={toggleMenu ? 2 : true} className={'headerLink'}>

              <div className="headerNavTitle" onClick={() => {
                navigate('/')
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                })
              }}>ESTEBAN INC.</div>

            </Grid>

            <Grid item className='menu-links'>
              <a className='menu-link-anchor' title='About' name="About" onClick={() => {
                const el = document.getElementById('content')
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start'
                })
                setToggleMenu(false)
              }}>About</a>
              <a className='menu-link-anchor' title='Selected Works' name='Selected Works' onClick={() => {
                const el = document.getElementById('selectedWorks')
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start'
                })
                setToggleMenu(false)
              }}>Selected Works</a>
              <a className='menu-link-anchor' title='Services' name='Services' onClick={() => {
                setToggleMenu(false)
                const el = document.getElementById('services')
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start'
                })
              }}>Services</a>
              <a className='menu-link-anchor' href="#resume" title='Resume' name='Resume' onClick={() => {
                setToggleMenu(false)
                const el = document.getElementById('resume')
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start'
                })
              }}>Resume & Clients</a>
              <a className='menu-link-anchor' title='Contact' name='Contact' onClick={() => {
                setToggleMenu(false)
                const el = document.getElementById('contact')
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start'
                })
              }}>Contact</a>
              {/* <Button
                title='Find a business card?'
                aria-label='Find a business card?'
                sx={{ marginRight: '10px' }}
                onClick={() => {
                  setBCMenu(true)
                }}>
                Find a business card?
              </Button> */}
              <IconButton
                title='Menu'
                aria-label='Menu'
                color="inherit" onClick={onClickGood}>
                {
                  !toggleMenu ?
                    <List /> :
                    <Close />
                }
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        {
          toggleMenu ?
            <Grid item xs={12}>
              <img loading='lazy' id="logoMain" src={eIncLogoWhite} title='Esteban Inc. Logo in White' alt='The Esteban Inc. Logo in white on a black background.' height={75} width={75} />
            </Grid>
            : null
        }

        <Grid item xs={12}>
          <h2 id={'menuText'} className={!toggleMenu ? "closed" : ""}>Graphic Designer + UI &amp; UX Designer + Front-End Developer + Artist + Gamer + Comic Book Enthusiast + Bacon Lover + Lego Collector + Birder + Sweet Tooth + Crime Junkie</h2>
        </Grid>


        {
          toggleMenu ?

            <Fade in={toggleMenu} style={{ transitionDelay: '1000ms' }}>
              <Grid item xs={12} className="currentStrip" >
                <Grid container>
                  <Grid item xs={12} lg={4}><h3><span>&#127918;</span> Elden Ring &amp; Pokemon Yellow</h3></Grid>
                  <Grid item xs={12} lg={4}> <h3><span>&#128214;</span>7 Habits of Highly Effective People &amp; Red Dragon</h3> </Grid>
                  <Grid item xs={12} lg={4}><h3><span>&#128250;</span> One-Piece </h3></Grid>
                </Grid>
              </Grid>
            </Fade>
            : null
        }


        {
          toggleMenu ?
            <Fade in={toggleMenu} style={{ transitionDelay: '1000ms' }}>

              <Grid item xs={12} className='mobileHeaderMenu'>
                <ul>
                  <li>
                    <a href="mailto:emylee@esteban-inc.com" target="_blank" title='Mail Link to Emylee@Esteban-inc.com'>EMYLEE@ESTEBAN-INC.COM</a>
                  </li>
                  <li>
                    <a href='https://www.instagram.com/esteban.inc/' title='Instagram' target='_blank'>INSTAGRAM</a>
                  </li>
                  <li>
                    <a href='https://estebaninc.etsy.com' title='Etsy' target='_blank'>ETSY</a>
                  </li>
                  <li>
                    <a href='https://www.tiktok.com/@esteban_inc' title='TikTok' target='_blank'>TIKTOK</a>
                  </li>
                  <li>  <a href='https://www.linkedin.com/in/emyleeesteban/' title='LinkedIn' target='_blank'>LINKEDIN</a></li>
                  <li>
                    <a href='/resume' title='LinkedIn' target='_blank'>RESUME</a>
                  </li>

                </ul>
              </Grid>
            </Fade>
            : null
        }


        {
          toggleMenu ?
            <Fade in={toggleMenu} style={{ transitionDelay: '1000ms' }}>


              <Grid item xs={12} className='enable-animation'>
                <div className='marquee'>
                  <div className='marquee__content' aria-hidden="true">
                    <div className='contactMarquee marquee__item el email'>
                      {
                        toggleMenu ?
                          <a href="mailto:emylee@esteban-inc.com" target="_blank" title='Mail Link to Emylee@Esteban-inc.com'>EMYLEE@ESTEBAN-INC.COM</a>
                          : null
                      }
                    </div>
                    <div className='contactMarquee marquee__item el linkedIn'>  {
                      toggleMenu ?
                        <a href='https://www.linkedin.com/in/emyleeesteban/' title='LinkedIn' target='_blank'>LINKEDIN</a>
                        : null
                    }
                    </div>
                    <div className='contactMarquee marquee__item el insta' >  {
                      toggleMenu ? <a href='https://www.instagram.com/esteban.inc/' title='Instagram' target='_blank'>INSTAGRAM</a> : null
                    }
                    </div>
                    <div className='contactMarquee marquee__item el etsy'>
                      {
                        toggleMenu ? <a href='https://estebaninc.etsy.com' title='Etsy' target='_blank'>ETSY</a> : null
                      }
                    </div>

                    <div className='contactMarquee marquee__item el tiktok'>
                      {
                        toggleMenu ? <a href='https://www.tiktok.com/@esteban_inc' title='TikTok' target='_blank'>TIKTOK</a> : null
                      }
                    </div>

                    <div className='contactMarquee marquee__item el resumeid'>
                      {
                        toggleMenu ? <a href='/resume' title='LinkedIn' target='_blank'>RESUME</a> : null
                      }
                    </div>
                  </div>
                  <div className='marquee__content' aria-hidden="true">
                    <div className='contactMarquee marquee__item el email'>
                      {
                        toggleMenu ?
                          <a href="mailto:emylee@esteban-inc.com" target="_blank" title='Mail Link to Emylee@Esteban-inc.com'>EMYLEE@ESTEBAN-INC.COM</a>
                          : null
                      }
                    </div>
                    <div className='contactMarquee marquee__item el linkedIn'> {
                      toggleMenu ?
                        <a href='https://www.linkedin.com/in/emyleeesteban/' title='LinkedIn' target='_blank'>LINKEDIN</a>
                        : null
                    }
                    </div>
                    <div className='contactMarquee marquee__item el insta' >  {
                      toggleMenu ? <a href='https://www.instagram.com/esteban.inc/' title='Instagram' target='_blank'>INSTAGRAM</a> : null
                    }
                    </div>
                    <div className='contactMarquee marquee__item el etsy'>
                      {
                        toggleMenu ? <a href='https://estebaninc.etsy.com' title='Etsy' target='_blank'>ETSY</a> : null
                      }
                    </div>

                    <div className='contactMarquee marquee__item el tiktok'>
                      {
                        toggleMenu ? <a href='https://www.tiktok.com/@esteban_inc' title='TikTok' target='_blank'>TIKTOK</a> : null
                      }
                    </div>
                    <div className='contactMarquee marquee__item el resumeid'>
                      {
                        toggleMenu ? <a href='/resume' title='Resume' target='_blank'>RESUME</a> : null
                      }
                    </div>

                  </div>

                </div>
              </Grid>
            </Fade>
            : null
        }


      </Grid>
    </nav >


  )
}