import EIncLogo from '../images/E-INC-LOGO.webp'
import '../styles/Loading.scss'

const Loading = () => {
    return (
        <div id="loader-container" style={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <h1 style={{ textTransform: 'uppercase' }}>Esteban Inc.</h1>
            <img id="EstebanIncLogo" src={EIncLogo} height={350} width={350} alt='The Esteban Inc. logo scaling up and down for loading.' title='Esteban Inc. Logo' aria-label='Esteban Inc. Logo' />

            <div className="progress-4"></div>

        </div>
    )
}
export default Loading;